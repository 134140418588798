import styles from './Intro.module.scss'
import React from 'react'
import cx from 'classnames'

import Container from '../../layouts/Container/Container'
import Text from '../Text/Text'
// import Herlambang from "./../../assets/images/h-italics--light.inline.svg"
import Section from '../../layouts/Section/Section'

const Hero = ({ className, text, ...restProps }) => {
  return (
    <Section className={cx(styles.root, className)} {...restProps}>
      <Container narrow className={styles.container}>
        <Text as="h1" display3 style={{ marginBottom: '48px' }}>
          Hi, I'm Ongki
        </Text>
        <Text
          className={styles.intro}
          as="p"
          large
          style={{ marginBottom: '24px' }}
        >
          I am a{' '}
          <span style={{ fontWeight: 500 }}>web designer &amp; developer</span>{' '}
          focusing on enhancing brands and business processes through a
          well-designed website and systems.
          <br />
          Making useful and beautiful digital products possible for everyone.
        </Text>
        <Text className={styles.intro} as="p" large>
          Let's start making your awesome product!
        </Text>
        <Text className={styles.intro} as="p" large>
          Send me your brief at{' '}
          <a
            href="mailto:ongki@herlambang.design"
            style={{ textDecoration: 'underline', fontWeight: 500 }}
          >
            ongki@herlambang.design
          </a>
        </Text>
      </Container>
    </Section>
  )
}

export default Hero
