import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { format, parseISO } from 'date-fns'

import Default from './../layouts/Default/Default'
import Container from '../layouts/Container/Container'

import Text from '../components/Text/Text'
import Link from '../components/Link/Link'

export const Post = ({ data, ...props }) => {
  let {
    frontmatter: { path, title, date, keywords },
    excerpt,
    timeToRead,
  } = data
  return (
    <Link to={path.replace('/posts', '')}>
      <div
        style={{
          padding: '24px 0',
          marginBottom: '24px',
          // color: 'rgb(202,226,218)',
          maxWidth: '60ch',
          borderBottom: '1px solid rgba(202,226,218,1)',
        }}
        {...props}
      >
        <header style={{ marginBottom: 16 }}>
          <Text medium>{format(parseISO(date), 'MMM dd, yyyy')}</Text>
          <Text large style={{ marginLeft: 6, marginRight: 6 }}>
            •
          </Text>
          <Text medium>{timeToRead} min read</Text>
        </header>
        <Text style={{ color: '#292929' }} heading2 as="h2">
          {title}
        </Text>
      </div>
    </Link>
  )
}

const Blogs = ({ className, ...restProps }) => {
  return (
    <StaticQuery
      query={query}
      render={({ allMdx: { edges } }) => {
        return (
          <Default>
            <Container narrow>
              <header style={{ paddingTop: '64px', paddingBottom: '64px' }}>
                <Text heading1 as="h1">
                  All Blog Posts
                </Text>
              </header>
              {edges.map(({ node: post }) => (
                <Post key={post.id} data={post} />
              ))}
            </Container>
          </Default>
        )
      }}
    />
  )
}

export default Blogs

const query = graphql`
  query allBlogPostsQuery {
    allMdx(filter: { frontmatter: { path: { glob: "/posts/*" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            keywords
          }
          timeToRead
          excerpt
        }
      }
    }
  }
`
