import React from 'react'
import { graphql } from 'gatsby'

import Default from '../layouts/Default/Default'
import SEO from '../utils/seo'
import Intro from '../components/Intro/Intro'
import LatestBlog from '../components/LatestBlog/LatestBlog'
import LatestWork from '../components/LatestWork/LatestWork'

const IndexPage = ({ data }) => {
  let {
    allPosts: { edges: posts },
    allWork: { edges: work },
    allWorkCovers: { nodes: allCovers },
  } = data
  return (
    <Default>
      <SEO
        lang="en"
        title="Home"
        description="I am a UX/UI designer and front-end web developer. I build and design digital products, mostly website. I love building website using React and Node.js"
        keywords={[
          `website`,
          `build`,
          `create`,
          `web`,
          `mobile`,
          `app`,
          `react`,
          `front-end`,
          `developer`,
          `programmer`,
          `bandung`,
          `agency`,
          `designer`,
          `application`,
          `react`,
        ]}
        author="Ongki 'vasilenka' Herlambang"
      />
      <Intro />
      <LatestWork allWork={work} allCovers={allCovers} />
      <LatestBlog data={posts} />
    </Default>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query homepageQuery {
    allPosts: allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { path: { glob: "/posts/*" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            keywords
            date
          }
          timeToRead
          excerpt
        }
      }
    }
    allWork: allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { path: { glob: "/work/*" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            keywords
            date
            cover
            path
            client
            selected
          }
          timeToRead
          excerpt
        }
      }
    }
    allWorkCovers: allImageSharp(
      filter: { original: { src: { regex: "/work/" } } }
    ) {
      nodes {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
