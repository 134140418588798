import styles from './LatestBlog.module.scss'
import React from 'react'
import cx from 'classnames'

import { Post } from './../../pages/blogs'
import Text from '../Text/Text'
import Container from '../../layouts/Container/Container'

const LatestBlog = ({ data, children, className, ...restProps }) => {
  return (
    <Container className={cx(styles.root)} bleed>
      <Container as="header" narrow style={{ marginBottom: 64 }}>
        <Text heading1 as="h2">
          Latest Blogs
        </Text>
      </Container>
      <Container as="article" post>
        {data.map(({ node: post }) => (
          <Post key={post.id} data={post} />
        ))}
      </Container>
    </Container>
  )
}

export default LatestBlog
